<template>
  <div class="detail">
    <detail-header title="资讯审核" />
    <div class="detail_main page_width">
      <div class="info">
        <p class="info_title tw-text-20px tw-font-bold">{{ formData.title }}</p>
        <p class="info_time tw-text-14px">{{ formData.updatedAt }}</p>
        <div
          class="info_content mce-content-body"
          v-html="formData.content"
        ></div>
      </div>
      <div class="op" v-if="formData.status === globalVar.INFO_STATUS_TOCHECK">
        <el-button
          :loading="btnLoading"
          type="primary"
          size="small"
          plain
          @click="checkInfo(true)"
        >发布</el-button>
        <el-button
          :loading="btnLoading"
          type="danger"
          size="small"
          plain
          @click="checkInfo(false)"
        >驳回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import globalVar from '@/configs/globalVar.js'
import * as api from '@/api'

export default {
  data () {
    return {
      formData: {},
      btnLoading: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  mounted () {
    this.getInfoDetail()
  },
  methods: {
    checkInfo (isPublished) {
      this.btnLoading = true
      const status = isPublished ? globalVar.INFO_STATUS_PUBLISHED : globalVar.INFO_STATUS_FAILED
      api
        .checkInfo(this.id, { status })
        .then((res) => {
          console.log(res)
          if (res.data.code === 0) {
            console.log('审核成功', res.data.data)
            this.$message.success('审核成功')
            this.$router.replace({ path: '/info-manage/info-check' })
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('请求出错')
        })
        .finally(() => {
          this.btnLoading = false
        })
    },
    getInfoDetail () {
      api
        .getInfoDetail(this.id)
        .then((res) => {
          if (res.data.code === 0) {
            this.formData = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('请求出错')
        })
        .finally(() => { })
    }
  }
}
</script>

<style lang="scss">
@import url("../../../assets/styles/content.min.scss");
</style>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
  }
}
.op {
  margin-left: 120px;
  line-height: 40px;
}
.info {
  width: 960px;
  margin: 0 auto;
  margin-bottom: 18px;
  &_title {
    text-align: center;
    color: #303133;
    line-height: 28px;
    padding: 16px 0;
  }
  &_time {
    color: #909399;
    line-height: 20px;
    text-align: center;
  }
  &_content {
    margin-top: 20px;
    img {
      max-width: 100%;
    }
  }
}
</style>
